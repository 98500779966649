export enum CheckoutViewStates {
  Initial,
  Campaign,
  Checkout,
  Success,
  Completed,
  NoCareTeam,
}

export enum CheckoutNavigationSource {
  ChatDetailPage = "chatDetailPage",
  GiftDetailPage = "giftDetailPage",
  CreditInfoPage = "creditInfoPage",
  HomeMeetingCard = "homeMeetingCard",
  ProfileCTA = "profileCTA",
  CheckoutDiscountedB2C = "checkoutDiscountedB2C",
  CheckoutOffer = "checkoutOffer",
  PlanDiscountedB2C = "planDiscountedB2C",
  AddProfessional = "addProfessional",
  Homepage = "homepage",
  CreditChoice = "creditChoice",
}

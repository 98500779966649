import { defineStore } from "pinia";
import { useAuthStore } from "~/store/auth";
import { CheckoutNavigationSource, CheckoutViewStates } from "~/model/checkout";
import type { Campaign } from "~/model/checkout/campaign";
import type { CheckoutForm } from "~/model/checkout/form";
import { Error } from "~/model/common";
import {
  createCheckout,
  submitPayment,
  retrieveCampaigns,
} from "~/helpers/api/checkout";
import { ProductType, SellableProduct } from "~/model/checkout/sellableProduct";
import { useUserStore } from "../user";
import type { UserType } from "~/model/user";
import type { MeetingPackage } from "~/model/meetingPackage";
import { capitalize } from "vue";

export const useCheckoutStore = defineStore("checkout", {
  state: () => ({
    _loading: {
      message: "Yükleniyor",
      description: "Sizi biraz bekletiyoruz...",
      show: false,
    },
    _errorModal: {
      show: false,
      message: "",
    },
    _errors: [] as Error[],
    _view: CheckoutViewStates.Initial,
    _checkout: null,
    _checkoutForm: null as CheckoutForm,
    _campaign: null as Campaign,
    _campaigns: null as Campaign[],
    _productInCheckout: null as SellableProduct,
    _tdsResponse: null,
    _paymentCallbackData: null,
    _tdsIframeProcessing: false,
    _selectedCampaign: null as Campaign | null,
    _selectedMeetingPackage: null as MeetingPackage | null,
    _checkoutNavigationSource: null as CheckoutNavigationSource | null,
    _selectedUserType: null as UserType | null,
    _checkoutUserType: null as UserType | null,
  }),
  getters: {
    isLoading(): boolean {
      return this._loading.show;
    },
    errors(): Error[] {
      return this._errors;
    },
    view(): CheckoutViewStates {
      return this._view;
    },
    campaign(): Campaign | null {
      return this._campaign;
    },
    campaigns(): Campaign[] | null {
      return this._campaigns;
    },
    campaignsByUserType(): Campaign[] | null {
      return this._campaigns?.filter(
        (c) => c.applicableProviderType === this.selectedUserType
      );
    },
    productInCheckout(): SellableProduct | null {
      return this._productInCheckout;
    },
    checkout(): any | null {
      return this._checkout;
    },
    checkoutNavigationSource(): CheckoutNavigationSource | null {
      return this._checkoutNavigationSource;
    },
    selectedUserType(): UserType | null {
      return this._selectedUserType;
    },
    formValidation(state): any {
      const { nameOnCard, number, cardExpiry, cvc, checkoutAgreement } =
        state._checkoutForm;
      const cleanedNumber = number?.replace(/\s/g, "");

      return {
        isNameOk: Boolean(nameOnCard),
        isNumberOk:
          Boolean(cleanedNumber) && [15, 16].includes(cleanedNumber.length),
        isCardExpiryOk:
          Boolean(cardExpiry) && /^\d{2}\/\d{2}$/.test(cardExpiry),
        isCvcOk: Boolean(cvc) && cvc.length <= 4,
        isAgreementOk: checkoutAgreement,
      };
    },
    isCompletePaymentDisabled(state): any {
      const validation = this.formValidation;
      return state._loading.show || Object.values(validation).some((v) => !v);
    },
    nameOnCardValidationErrors(state) {
      const { nameOnCard } = state._checkoutForm;
      const errors = [!nameOnCard ? "Kart Üzerindeki İsim boş olamaz" : null];
      return errors.filter(Boolean);
    },
    numberValidationErrors(state) {
      const cleanedNumber = state._checkoutForm.number?.replace(/\s/g, "");
      var errors = [
        !state._checkoutForm.number ? "Kart Numarası boş olamaz" : null,
        cleanedNumber && ![15, 16].includes(cleanedNumber.length)
          ? "Kart Numarası geçersiz"
          : null,
      ];
      return errors.filter(Boolean);
    },
    cardExpiryValidationErrors(state) {
      var errors = [
        state._checkoutForm.cardExpiry === null ||
        state._checkoutForm.cardExpiry === ""
          ? "Son Kullanma Tarihi boş olamaz"
          : null,
        !/^\d{2}\/\d{2}$/.test(state._checkoutForm.cardExpiry)
          ? "Son Kullanma Tarihi geçerli değil"
          : null,
      ];
      return errors?.filter((x) => x && x.length > 0);
    },
    cvcValidationErrors(state) {
      var errors = [
        !state._checkoutForm.cvc ? "CVC boş olamaz" : null,
        state._checkoutForm.cvc?.length > 4 ? "CVC geçerli değil" : null,
      ];
      return errors.filter(Boolean);
    },
    paidWithTds(state) {
      return state._tdsResponse !== null;
    },

    selectedCampaign(state) {
      return state._selectedCampaign;
    },
    selectedMeetingPackage(state) {
      return state._selectedMeetingPackage;
    },
    checkoutUserType(state) {
      return state._checkoutUserType;
    },
  },
  actions: {
    setView(view: CheckoutViewStates) {
      this._view = view;
    },
    async initialize() {
      this._checkoutForm = {
        nameOnCard: "",
        number: "",
        cardExpiry: "",
        cvc: "",
        checkoutAgreement: false,
        is3DSecure: false,
      };
      this._productInCheckout = null;
      this._tdsResponse = null;
      this._tdsIframeProcessing = false;
      await this.getEligibleCampaign();
    },
    async sendPayment() {
      this._loading.show = true;
      var authStore = useAuthStore();
      try {
        const originUrl = window.location.origin;
        var tdsRequest = null;
        var paymentCard = null;
        if (this._paymentCallbackData) {
          tdsRequest = {
            state: "Complete",
            paymentCallbackData: this._paymentCallbackData,
          };
          this._paymentCallbackData = null;
        } else {
          paymentCard = {
            nameOnCard: this._checkoutForm.nameOnCard,
            number: this._checkoutForm.number.replaceAll(" ", ""),
            expireMonth: this._checkoutForm.cardExpiry.split("/")[0],
            expireYear: this._checkoutForm.cardExpiry.split("/")[1],
            cvc: this._checkoutForm.cvc,
          };
          if (this._checkoutForm.is3DSecure) {
            tdsRequest = {
              state: "Initialize",
            };
          }
        }
        var paymentResult = await submitPayment(
          authStore.token,
          this._checkout.id,
          null,
          paymentCard,
          null,
          null,
          tdsRequest,
          originUrl
        );
        if (paymentResult.status == "Failure") {
          this._errorModal = {
            show: true,
            message: paymentResult.message,
          };
        } else if (paymentResult.status == "TdsRedirect") {
          this._loading.message =
            "3D secure ile güvenli ödemeye yönlendiriliyorsunuz";
          await new Promise((resolve) => setTimeout(resolve, 1500));
          this._tdsIframeProcessing = true;
          this._tdsResponse = {
            paymentId: paymentResult.tdsResponse.paymentId,
            htmlContent: paymentResult.tdsResponse.htmlContent,
          };
        } else {
          const userStore = useUserStore();
          userStore.refreshUserProfile();
          authStore.refreshUser();
          this._campaigns = await retrieveCampaigns(authStore.token, null);
          this._view = CheckoutViewStates.Success;
        }
      } catch (error) {
        this._errorModal = {
          show: true,
          message:
            "Lütfen bilgilerinizi kontrol edin ve tekrar deneyin. Hatanın devam etmesi durumunda bankanızla iletişime geçin.",
        };
      }
      this._loading.show = false;
    },
    clearErrorModal() {
      this._errorModal = {
        show: false,
        message: "",
      };
    },
    clearProductInCheckout() {
      this._campaign = null;
      this._campaigns = null;
      this._productInCheckout = null;
      this._selectedCampaign = null;
      this._selectedMeetingPackage = null;
      this._selectedUserType = null;
      this._checkoutUserType = null;
      this._tdsResponse = null;
      this._tdsIframeProcessing = false;
    },
    clearEverything() {
      this.$reset();
    },
    async prepareForPayment() {
      if (this.selectedCampaign) {
        await this.prepareCampaignCheckoutForPayment(this.selectedCampaign);
      } else if (this.selectedMeetingPackage) {
        await this.prepareMeetingPackageOptionCheckoutForPayment();
      }
    },
    async prepareCampaignCheckoutForPayment(campaign: Campaign) {
      if (!this.selectedCampaign) {
        this.setSelectedUserType(campaign.applicableProviderType);
        this.setSelectedCampaign(campaign);
      }

      this._checkoutForm = {
        nameOnCard: "",
        number: "",
        cardExpiry: "",
        cvc: "",
        checkoutAgreement: false,
        is3DSecure: false,
      };
      this._productInCheckout = {
        planAlias: "",
        sku: campaign.offers[0].sku,
        name: campaign.offers[0].name,
        price: campaign.offers[0].price,
        salePrice: campaign.offers[0].salePrice,
        productType: ProductType.Offer,
        credit: null,
        toJSON: () => Object.assign({}, this._productInCheckout),
      };
      const authStore = useAuthStore();
      this._checkout = await createCheckout(
        authStore.token,
        this.productInCheckout.sku,
        this.productInCheckout.productType,
        campaign.id
      );
      this._view = CheckoutViewStates.Checkout;
    },
    async prepareMeetingPackageOptionCheckoutForPayment() {
      this._checkoutForm = {
        nameOnCard: "",
        number: "",
        cardExpiry: "",
        cvc: "",
        checkoutAgreement: false,
        is3DSecure: false,
      };
      this._productInCheckout = {
        planAlias: "",
        sku: this.selectedMeetingPackage.sku,
        name: this.selectedMeetingPackage.name,
        price: this.selectedMeetingPackage.price,
        salePrice: this.selectedMeetingPackage.salePrice,
        productType: ProductType.MeetingRight,
        toJSON: () => Object.assign({}, this._productInCheckout),
      };
      const authStore = useAuthStore();
      this._checkout = await createCheckout(
        authStore.token,
        this.productInCheckout.sku,
        this.productInCheckout.productType,
        null
      );
      this._view = CheckoutViewStates.Checkout;
    },
    async getEligibleCampaign(userType: UserType = null) {
      if (this._campaign == null) {
        var authStore = useAuthStore();
        var campaigns: Campaign[] = await retrieveCampaigns(
          authStore.token,
          null
        );
        if (campaigns.length > 0) {
          campaigns = campaigns.filter(
            (c) =>
              c.type == "FirstMeeting" ||
              c.type == "Habit" ||
              c.type == "Reactivation"
          );

          this._campaign = userType
            ? campaigns.find((i) => i.applicableProviderType === userType)
            : campaigns[0];
          this._campaigns = campaigns;
        }
      }

      return this._campaign;
    },
    async getAllCampaigns() {
      var authStore = useAuthStore();
      this._campaigns = await retrieveCampaigns(authStore.token, null);
    },
    setCheckoutNavigationSource(source: CheckoutNavigationSource) {
      this._checkoutNavigationSource = source;
    },
    setSelectedUserType(userType: UserType) {
      this._selectedUserType = userType;
    },
    setSelectedCampaign(campaign: Campaign) {
      this._selectedCampaign = campaign;
      this._checkoutUserType = this.selectedUserType;
      this._selectedMeetingPackage = null;
    },
    setSelectedMeetingPackage(meetingPackage: MeetingPackage) {
      this._selectedMeetingPackage = meetingPackage;
      this._checkoutUserType = this.selectedUserType;
      this._selectedCampaign = null;
    },
  },
});

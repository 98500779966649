import { axiosInstance } from "~/plugins/axios";

export const createCheckout = async (token, sku, type, campaignId) => {
  let params = {
    token: token,
    sku: sku,
    type: type,
    campaignId: campaignId,
  };
  const { data } = await axiosInstance.post(
    "/api/checkout/create-checkout",
    params
  );
  return data;
};

export const submitPayment = async (
  token,
  checkoutId,
  userCardToken,
  paymentCard,
  storeCard,
  storeCardAlias,
  tdsRequest,
  originUrl
) => {
  let params = {
    token: token,
    checkoutId: checkoutId,
    userCardToken: userCardToken,
    paymentCard: paymentCard,
    storeCard: storeCard,
    storeCardAlias: storeCardAlias,
    tdsRequest: tdsRequest,
    originUrl: originUrl,
  };
  const { data } = await axiosInstance.post(
    "/api/checkout/submit-payment",
    params
  );
  return data;
};

export const retrieveCampaigns = async (token, campaignType) => {
  let params = {
    token: token,
    campaignType: campaignType,
  };
  const { data } = await axiosInstance.post(
    "/api/checkout/retrieve-campaigns",
    params
  );
  return data;
};

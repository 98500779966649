import { HeltiaBase } from "~/model/common";

export class SellableProduct extends HeltiaBase {
  planAlias?: string;
  sku: string;
  name: string;
  price: number;
  salePrice: number;
  productType?: ProductType;
  credit?: number;

  constructor(
    planAlias: string,
    sku: string,
    name: string,
    price: number,
    salePrice: number,
    productType: ProductType,
    credit: number
  ) {
    super();
    this.planAlias = planAlias;
    this.sku = sku;
    this.name = name;
    this.price = price;
    this.salePrice = salePrice;
    this.productType = productType;
    this.credit = credit;
  }
}

export enum ProductType {
  TopUpOption = "TopUpOption",
  MeetingRight = "MeetingRight",
  Plan = "Plan",
  Offer = "Offer",
}
